var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{staticClass:"pa-2 elevation-4 rounded-t",attrs:{"dark":"","color":"primary"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":[
                            {
                                text: 'Name', 
                                value: '__name',
                            } ],"item-value":function (item) { return item; },"light":"","flat":"","solo":"","hide-details":""},model:{value:(_vm.search.field),callback:function ($$v) {_vm.$set(_vm.search, "field", $$v)},expression:"search.field"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{attrs:{"flat":"","solo-inverted":"","hide-details":"","clearable":"","prepend-inner-icon":"mdi-magnify","type":"search","label":("Search Courses by " + (_vm.search.field.text))},model:{value:(_vm.search.term),callback:function ($$v) {_vm.$set(_vm.search, "term", $$v)},expression:"search.term"}}),_c('v-spacer')],1)],1)],1),_c('v-data-iterator',{attrs:{"items":_vm.courses,"loading":_vm.isLoadingCourses,"server-items-length":_vm.pagination.itemsTotalCount,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"must-sort":"","hide-default-footer":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-sheet',{staticStyle:{"border":"thin solid rgba(0, 0, 0, 0.12) !important"},attrs:{"color":"white"}},[_c('div',{staticClass:"table-top px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}}),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","md":"3"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"secondary","icon":"","small":"","loading":_vm.isReloadingCourses},on:{"click":function($event){return _vm.reloadCourses()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Reload")])]),_c('div',{staticClass:"caption text-right"},[_vm._v(" showing "+_vm._s(_vm.courses.length)+" of "+_vm._s(_vm.pagination.itemsTotalCount)+" results ")])],1)],1)],1)])]},proxy:true},{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(course){return _c('v-col',{key:course.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('course-swatch',{attrs:{"course":course},on:{"click":function($event){return _vm.showCourseOverview(course)}}})],1)}),1)]}},((_vm.pagination.itemsTotalCount > _vm.courses.length) && !_vm.isLoadingCourses)?{key:"footer",fn:function(){return [_c('div',{staticClass:"table-bottom px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.loadMoreCourses()}}},[_vm._v(" Load More ... ")])],1)])],1)],1)]},proxy:true}:null],null,true)}),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.isShowingCourseOverviewDialog),callback:function ($$v) {_vm.isShowingCourseOverviewDialog=$$v},expression:"isShowingCourseOverviewDialog"}},[_c('course-overview',{attrs:{"course":_vm.shownCourseForOverview}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }