import { render, staticRenderFns } from "./Review.vue?vue&type=template&id=74db2a69&scoped=true&"
import script from "./Review.vue?vue&type=script&lang=js&"
export * from "./Review.vue?vue&type=script&lang=js&"
import style0 from "./Review.vue?vue&type=style&index=0&id=74db2a69&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74db2a69",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCol,VRating,VRow})
